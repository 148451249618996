var exports = {};

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var _Symbol = function _Symbol(key) {
  return typeof Symbol !== "undefined" ? Symbol(key) : key;
};

var KEYS = {
  __id__: _Symbol("__id__"),
  __store__: _Symbol("__store__"),
  __prev__: _Symbol("__prev__"),
  __use__: _Symbol("__use__"),
  __elements__: "__elements__",
  __style__: "$$style",
  __styles__: "styles",
  __css__: "__css__",
  __hash__: "__hash__",

  /**
   * This prop is needed for the interop between different component frameworks
   */
  __classProp__: "className"
};
var index = 0;

var use = function use(obj) {
  var result = {};
  result[KEYS.__use__] = obj;
  return result;
};

var create = function create(args) {
  var len = args.length;
  var newStyle = {};
  var id = "";
  var vars = null;
  var uses = null;

  for (var i = 0; i < len; i++) {
    var _style = args[i];
    if (!_style) continue;

    if (!_style[KEYS.__id__]) {
      _style[KEYS.__id__] = ++index;
      _style[KEYS.__store__] = _defineProperty({}, "_" + _style[KEYS.__id__], _style);
    }

    id += "_" + _style[KEYS.__id__];

    if (_style[KEYS.__style__]) {
      vars = Object.assign(vars || {}, _style[KEYS.__style__]);
    }

    if (_style[KEYS.__use__]) {
      uses = Object.assign(uses || {}, _style[KEYS.__use__]);
    }

    if (_style[KEYS.__store__][id]) {
      newStyle = _style[KEYS.__store__][id];
      continue;
    }

    newStyle = Object.create(newStyle);

    for (var key in _style) {
      if (key in KEYS) continue;
      newStyle[key] = appendClassName(_style[key], newStyle[key]);
    }

    _style[KEYS.__store__][id] = newStyle;
    newStyle[KEYS.__id__] = index;
    newStyle[KEYS.__store__] = _defineProperty({}, "_" + _style[KEYS.__id__], newStyle);
  }

  if (vars || use) {
    newStyle = Object.create(newStyle);
    newStyle[KEYS.__style__] = vars;
    newStyle[KEYS.__use__] = uses;
  }

  return newStyle;
};

var isSSR = !(typeof window !== "undefined" && window.document && window.document.createElement);
var serverMap = {};

var getStyles = function getStyles() {
  return {
    map: serverMap,

    get css() {
      var serverStyles = "";

      for (var id in serverMap) {
        serverStyles += "<style type=\"text/css\" id=\"".concat(id, "\">").concat(serverMap[id], "</style>");
      }

      return serverStyles;
    }

  };
};
/* eslint-disable no-undef */


var clearStyles = function clearStyles() {
  serverStyles = "";
};
/* eslint-disable no-undef */


var RESHADOW_ID = "__reshadow__";

var appendChildToDocumentHead = function appendChildToDocumentHead(element) {
  return document.head.appendChild(element);
};

var css = function css(code, hash) {
  var id = "reshadow-".concat(hash);

  if (isSSR) {
    serverMap[id] = code;
    return;
  }

  var container = document.getElementById(RESHADOW_ID);

  if (!container) {
    container = document.createElement("object");
    container.id = RESHADOW_ID;

    if (document.head) {
      appendChildToDocumentHead(container);
    } else {
      document.addEventListener("DOMContentLoaded", function () {
        appendChildToDocumentHead(container);
      });
    }
  }

  var css = document.getElementById(id);

  if (!css) {
    css = document.createElement("style");
    css.id = id;
    css.type = "text/css";
    container.appendChild(css);
  }

  css.innerHTML = code;
};

var styles = {};
var style;
var stack = [];

var styled = function styled(elem) {
  var curr = stack.pop() || [];
  styles = curr[0] || styles;
  style = curr[1] || style;
  styled[KEYS.__styles__] = styles;
  styled[KEYS.__style__] = style;
  return elem;
};

styled[KEYS.__styles__] = styles;

var set = function set(args, newStyle) {
  var newStyles = create(args);
  stack.push([styles, style]);
  styles = newStyles;
  style = newStyle;

  if (styles[KEYS.__style__]) {
    style = style ? Object.assign({}, style, styles[KEYS.__style__]) : styles[KEYS.__style__];
  }

  styled[KEYS.__styles__] = styles;
  styled[KEYS.__style__] = style;
};

var USE_PREFIX = "use--";
var ELEMENT_PREFIX = "__";
var MOD_PREFIX = "_";
var MOD_SEPARATOR = "_";

var parseElement = function parseElement(name) {
  return name.replace(ELEMENT_PREFIX, "");
};

var parseAttribute = function parseAttribute(name) {
  return name.replace(MOD_PREFIX, "").split(MOD_SEPARATOR);
};

var appendClassName = function appendClassName(className) {
  var cn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

  if (className) {
    cn += (cn ? " " : "") + className;
  }

  return cn;
};

var appendElement = function appendElement(styles, key) {
  var cn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  return appendClassName(styles[ELEMENT_PREFIX + key], cn);
};

var appendModifier = function appendModifier(styles, key, value) {
  var cn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ""; // isFalsy

  if (value === undefined || value === null || value === false || value === "") return cn;
  cn = appendClassName(styles[MOD_PREFIX + key], cn); // value should be only primitive

  if (typeof value === "boolean" || _typeof(value) === "object" || typeof value === "function") return cn;
  cn = appendClassName(styles[MOD_SEPARATOR + key + MOD_SEPARATOR + value], cn);
  return cn;
};

function map(element) {
  var currStyles = styled[KEYS.__styles__];
  var nextProps = {};
  var cn = appendElement(currStyles, element);
  var vars = null;
  var uses = currStyles[KEYS.__use__] || {};
  var len = arguments.length;
  var useProps;

  for (var i = len - 1; i > 0; i--) {
    var currProps = arguments[i];
    if (!currProps) continue;
    useProps = useProps || currProps[KEYS.__use__];

    if (!vars && KEYS.__style__ in currProps) {
      vars = styled[KEYS.__style__];
    }

    for (var key in currProps) {
      if (key === KEYS.__use__ || key === KEYS.__style__ || key in nextProps) {
        continue;
      }

      var value = currProps[key];
      cn = appendModifier(currStyles, key, value, cn);

      var valueType = _typeof(value);

      if (valueType === "string" || valueType === "boolean" || valueType === "number") {
        var useKey = key + "_" + value;

        if (key + "_" + true in uses || useKey in uses) {
          cn = appendModifier(currStyles, USE_PREFIX + key, value, cn);

          if (uses[useKey]) {
            continue;
          }
        }
      }

      nextProps[key] = value;
    }
  }

  if (useProps) {
    for (var _key in useProps) {
      var _value = useProps[_key];
      cn = appendModifier(currStyles, USE_PREFIX + _key, _value, cn);
    }
  }

  cn = appendClassName(nextProps[KEYS.__classProp__], cn);
  if (cn) nextProps[KEYS.__classProp__] = cn;

  if (vars) {
    nextProps.style = typeof style === "string" ? vars + (nextProps.style || "") : Object.assign({}, vars, nextProps.style || {});
  }

  return nextProps;
}

exports = {
  __esModule: true,
  "default": styled,
  use: use,
  css: css,
  create: create,
  set: set,
  map: map,
  __css__: css,
  __extract__: function __extract__() {
    var _ref;

    return _ref = {}, _defineProperty(_ref, KEYS.__styles__, styled[[KEYS.__styles__]]), _defineProperty(_ref, KEYS.__style__, styled[[KEYS.__style__]]), _ref;
  },
  // ssr
  getStyles: getStyles,
  clearStyles: clearStyles,
  // utils
  appendModifier: appendModifier,
  appendElement: appendElement,
  appendClassName: appendClassName,
  parseAttribute: parseAttribute,
  parseElement: parseElement,
  // constants
  MOD_SEPARATOR: MOD_SEPARATOR,
  MOD_PREFIX: MOD_PREFIX,
  ELEMENT_PREFIX: ELEMENT_PREFIX,
  USE_PREFIX: USE_PREFIX,
  KEYS: KEYS,
  RESHADOW_ID: RESHADOW_ID
};
export default exports;
export const __esModule = exports.__esModule,
      __css__ = exports.__css__,
      __extract__ = exports.__extract__;
const _use = exports.use,
      _css = exports.css,
      _create = exports.create,
      _set = exports.set,
      _map = exports.map;
export { _use as use, _css as css, _create as create, _set as set, _map as map };